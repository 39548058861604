<template :id="id">
  <div class="dropdown">
    <button
      class="capitalize dropdown-toggle btn box text-gray-700 dark:text-gray-300 flex items-center"
      :class="buttonDropdownMenuClass"
    >
      {{ getText(selected) }}
      <ChevronDownIcon :class="chevronDropdownMenuClass" />
    </button>
    <div class="dropdown-menu" :class="dropdownMenuClass">
      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
        <a
          v-for="(option, index) in options"
          :key="index"
          class="transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
          @click="onClick(option)"
        >
          <slot name="option" :option="option" :index="index">
            <div
              class="capitalize truncate"
              :class="[
                { 'mt-2': index !== 0 },
                { 'text-gray-400 cursor-not-allowed': option.disabled },
                { 'cursor-pointer': !option.disabled }
              ]"
            >
              {{ option.text }}
            </div>
          </slot>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from "vue";

export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    selected: {
      type: String,
      default: ""
    },
    dropdownMenuClass: {
      type: String,
      default: "w-40"
    },
    buttonDropdownMenuClass: {
      type: String,
      default: ""
    },
    chevronDropdownMenuClass: {
      type: String,
      default: "w-4 h-4 ml-2"
    },
    hideDropdownOnClick: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"],
  setup(props, context) {
    // Inject
    const _ = inject("lodash");

    // Data
    const id = ref(_.uniqueId());

    // Methods
    const getText = value => {
      const selected = props.options.find(
        option => option.value === value || option.title === value
      );

      return selected ? selected.text ?? selected.title : "";
    };

    const onClick = option => {
      if (option.disabled) {
        return;
      }
      context.emit("click", option.value ?? option);
      props?.hideDropdownOnClick && hideDropdown();
    };

    const hideDropdown = () => {
      cash(id).dropdown("hide");
    };

    return {
      getText,
      onClick
    };
  }
};
</script>
