<template>
  <div>
    <div class="flex items-center justify-between">
      <VTitle class="flex justify-baseline items-center route-title">
        {{ $t("app.lessons", 2) }}

        <VHint class="ml-4 text-base">
          {{ $t("hints.learning_lessons") }}
        </VHint>
      </VTitle>

      <VDropdown
        dropdown-menu-class="w-48"
        :selected="selectedStatus"
        :options="statusOptions"
        @click="selectedStatus = $event"
      >
        <template #option="{ option }">
          <div class="cursor-pointer p-2 flex justify-between items-center">
            <div class="truncate w-2/3">
              {{ option.text }}
            </div>

            <div
              v-if="option.value !== 'all'"
              class="rounded-full w-3 h-3"
              :style="{ 'background-color': getRGBStatusColor(option.value) }"
            />
          </div>
        </template>
      </VDropdown>
    </div>

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <VAlert v-else-if="data.length === 0" :text="$t('app.no_results_found')" />

    <div v-else>
      <div class="grid grid-cols-2 gap-8">
        <div v-for="(lesson, index) in data" :key="index">
          <LessonCard :resource="lesson" @click="onClickRedirectToLesson" />
        </div>
      </div>

      <FullPagination
        :pagination="pagination"
        @update:per_page="onUpdatePerPage"
        @update:current_page="onUpdateCurrentPage"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
import useColor from "@/composables/useColor";
import useOptions from "@/composables/useOptions";
import usePaginate from "@/composables/usePaginate";
// Components
import LessonCard from "./LessonCard";
import VTitle from "@/components/VTitle";
import FullPagination from "@/components/tables/FullPagination";
import VAlert from "@/components/VAlert";
import VDropdown from "@/components/VDropdown";
import VHint from "@/components/VHint";

export default {
  components: {
    VTitle,
    VHint,
    LessonCard,
    VAlert,
    FullPagination,
    VDropdown
  },
  setup() {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Composables
    const { data, isLoading, read, pagination } = useReadMultiple();
    const { ALL_OPTIONS } = useOptions();
    const { getRGBStatusColor } = useColor();

    // Data
    const selectedStatus = ref("all");

    // Constants
    const documentTitle = `${t("app.lessons", 2)} - ${t("app.learning")}`;
    const statusOptions = [
      {
        text: t("app.all_statuses"),
        value: "all"
      },
      ALL_OPTIONS.PAUSED,
      ALL_OPTIONS.PENDING,
      ALL_OPTIONS.IN_PROGRESS,
      ALL_OPTIONS.COMPLETED,
      ALL_OPTIONS.WITHDRAWN,
      ALL_OPTIONS.CANCELLED
    ];

    // Methods
    const getData = async () => {
      let queryParams = {};

      if (selectedStatus.value !== "all") {
        queryParams = {
          ro_f: JSON.stringify([
            {
              f: "status",
              c: "EQUAL",
              v: [selectedStatus.value]
            }
          ])
        };
      }

      await read({
        endpoint: "learning.lessons",
        queryParams
      });
    };

    const onClickRedirectToLesson = id => {
      router.push({
        name: "learning-lessons-details",
        params: { id }
      });
    };

    // Composables
    const { onUpdatePerPage, onUpdateCurrentPage } = usePaginate(
      pagination,
      getData
    );

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    // Watch
    watch(selectedStatus, async () => {
      await getData();
    });

    return {
      documentTitle,
      selectedStatus,
      statusOptions,
      onClickRedirectToLesson,
      // useColor
      getRGBStatusColor,
      // useReadMultiple
      data,
      isLoading,
      pagination,
      // usePaginate
      onUpdateCurrentPage,
      onUpdatePerPage
    };
  }
};
</script>
